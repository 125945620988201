import List from './primitives/List';
import Section from './primitives/Section';

type Props = {
  children?: React.ReactNode;
  heading: string | React.ReactNode;
  sectionUrl?: string;
};

function LatestNews({ heading, sectionUrl, children }: Props) {
  return !React.Children.count(children) ? null : (
    <Section dataTest="section-news" header={heading} url={sectionUrl}>
      <List data-test="latest-news-list">{children}</List>
    </Section>
  );
}

export default LatestNews;
